import { createContext, useEffect, useReducer } from 'react';
import { firebase, PaviFirestore } from './Firebase';

export interface InitialStateType {
  isAuthenticated?: boolean;
  isInitialized?: boolean;
  user?: any | null | undefined;
}

const initialState: InitialStateType = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state: InitialStateType, action: any) => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext<any | null>({
  ...initialState,
  platform: 'Firebase',
  signin: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  onceGetUsers: () => Promise.resolve(),
  CreateUser: () => Promise.resolve(),
});

export const AuthProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          PaviFirestore.doc(`test_collection/${user?.email}`)
            .get()
            .then((doc) => {
              if (doc.exists) {
                // Here you should extract the complete user profile to make it available in your entire app.
                // The auth state only provides basic information.
                dispatch({
                  type: 'AUTH_STATE_CHANGED',
                  payload: {
                    isAuthenticated: true,
                    user: {
                      id: user.uid,
                      avatar: user.photoURL,
                      email: user.email,
                      name: doc.data()?.name || '',
                      menuData: doc.data()?.menudata || [],
                    },
                  },
                });
              } else {
                console.log('No such document!');
                dispatch({
                  type: 'AUTH_STATE_CHANGED',
                  payload: {
                    isAuthenticated: true,
                    user: {
                      id: user.uid,
                      avatar: user.photoURL,
                      email: user.email,
                      name: user.displayName,
                    },
                  },
                });
              }
            })
            .catch((error) => {
              console.log(error);
              dispatch({
                type: 'AUTH_STATE_CHANGED',
                payload: {
                  isAuthenticated: true,
                  user: null,
                },
              });
            });

        } else {
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }),
    [dispatch],
  );

  // Sign In
  const signin = (email: string, password: string) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  // Sign out
  const logout = () => firebase.auth().signOut();

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Firebase',
        signin,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
