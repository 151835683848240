import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyANoX3PFaQW-Y1jHiv88q8R0_E7RgzWt6E',
  authDomain: 'fir-test-fcc3e.firebaseapp.com',
  projectId: 'tokendon-client-portal',
  storageBucket: 'fir-test-fcc3e.appspot.com',
  messagingSenderId: '973717435451',
  appId: '1:582227232675:web:0b17c42ba3b3fa40c6fc16',
  measurementId: 'G-0477D2W6Z6',
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const Auth = firebase.auth();
const PaviFirestore = firebase.firestore();

export { Auth, PaviFirestore, firebase };
