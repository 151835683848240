import React, { lazy, useContext, useEffect, useState, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import AuthGuard from 'src/guards/authGuard/AuthGuard';

import GuestGuard from 'src/guards/authGuard/GuestGuard';
import IFramePage from 'src/views/iframe-page/IFramePage';
import AuthContext from 'src/guards/firebase/FirebaseContext';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/* ****Pages***** */
const SamplePage = Loadable(lazy(() => import('../views/sample-page/SamplePage')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/auth/Login')));

interface SubmenuItem {
  key: string;
  name: string;
  url: string;
}

interface MenuItem {
  id: string;
  title: string;
  key: string;  // Assuming every MenuItem has a 'key' property
  submenu?: SubmenuItem[]; // Make submenu optional
}

function determineFirstMenuItem(menuData: MenuItem[]): string {
  if (menuData.length === 0) {
    return 'fallback'; // Return a fallback if menuData is empty
  }

  // Check the first item for a submenu, if it exists and has entries, return the first submenu's key
  const firstItem = menuData[0];
  if (firstItem.submenu && firstItem.submenu.length > 0) {
    return firstItem.submenu[0].key;
  }

  // If no submenu, return the ID of the first item
  return firstItem.key;
}

const RouterConfig = () => {
  const ac = useContext(AuthContext);
  const { user } = ac;

  const firstMenuItem = useMemo(() => determineFirstMenuItem(user?.menuData || []), [user?.menuData]);

  return [
    {
      path: '/',
      element: (
        <AuthGuard>
          <FullLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to={`/app/${firstMenuItem}`} replace /> },
        { path: '/sample-page', element: <SamplePage /> },
        { path: '/app/:appId', exact: true, element: <IFramePage /> },
        { path: '/app/fallback', element: <Error /> },
        { path: '*', element: <Navigate to="/auth/404" replace /> },
      ],
    },
    {
      path: '/auth',
      element: (
        <GuestGuard>
          <BlankLayout />
        </GuestGuard>
      ),
      children: [
        { path: '/auth/login', element: <Login /> },
        { path: '/auth/404', element: <Error /> },
      ],
    },
  ];
};

export default RouterConfig;
