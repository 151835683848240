import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AuthContext from 'src/guards/firebase/FirebaseContext';
import PageContainer from 'src/components/container/PageContainer';

interface NavGroup {
  key: string;
  dashboard?: string;
  submenu?: NavGroup[];
}

export default function IFramePage() {
  const ac = useContext(AuthContext);
  const { user } = ac;
  const menuData: NavGroup[] = user?.menuData;

  const { appId } = useParams();
  const [ifr, setIframe] = useState<string>(''); // Initialize with an empty string

  useEffect(() => {
    let foundUrl = ''; // Initialize with an empty string

    for (const item of menuData) {
      if (item.key === appId) {
        foundUrl = item.dashboard || ''; // Fallback to empty string if undefined
        break;
      }

      if (item.submenu) {
        const foundSubItem = item.submenu.find(sub => sub.key === appId);
        if (foundSubItem) {
          foundUrl = foundSubItem.dashboard || ''; // Fallback to empty string if undefined
          break;
        }
      }
    }

    console.log('Found URL:', foundUrl);
    setIframe(foundUrl); // foundUrl is guaranteed to be a string here
  }, [menuData, appId]);  

  return (
    <PageContainer title="Dashboard" description="Your dashboard overview" height="100vh">
      {ifr ? (
        <iframe src={ifr} width="100%" height="100%" scrolling="no" title="Power-BI-Stats" style={{ border: 'none' }} />
      ) : (
        <div>No content available.</div>
      )}
    </PageContainer>
  );
}